/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
//import utils from '../../utils';

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />

        <div className='img__container' css={style.img__container}>
          <SiteLink
            to='/'
            css={[style.logo, scrolled && style.logoScrolled]}
            onClick={backTop}
          >
            <img
              css={style.logoImg}
              src={
                scrolled && style.logoScrolled
                  ? "/assets/logos/logo2.png"
                  : "/assets/logos/logo.png"
              }
              alt='logo'
            />
          </SiteLink>
        </div>

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />

        {/* <div className='contact__us__btn__top' css={style.contact__us__btn__top}>
          <a className="button" href='#contact' onClick={(e) => {
            e.preventDefault();
            //e.stopPropagation();
            //utils.ui.scrollTo("#contact");
          }}>
              Contact Us
          </a>
        </div> */}

        {/* <div className='contact__us__btn__top' css={style.contact__us__btn__top}>
          <button className='btn btn-primary'>
            Contact Us
          </button>
        </div> */}

        <button className='btn btn-primary' css={style.contact__us__btn__top}>
          <SiteLink to='#contact'>Contact Us</SiteLink>
        </button>

        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
