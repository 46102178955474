import { css } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    //background: 'transparent',
    background: variables.colorGrey,
    //background: 'red',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    //background: variables.colorGrey
  })),
  logo: css(mq({
    position: 'absolute',
    //top: ['90%',null,'70%'],
    left: ['42px',null,'100px'],
    transform: 'translateY(-50%)',
    top: '50% !important',
    // left: '50% !important',
    // transform : 'translate(-50%, -50%)'

  })),
  logoImg: css(mq({
    display: 'block',
    height: [30, null, 47],
    //marginTop: ['3rem', null, '3.5rem'],
    transition: 'all .2s'
  })),
  logoScrolled: css(mq({

    //top: '50% !important',
    //transform: 'translateY(-50%)',

    img: css(mq({
      // height: [20, null, 30],
      // marginTop: '0rem'
    }))

  })),

  img__container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  contact__us__btn__top : mq({
    position: 'fixed',
    right: ['60px',null,'110px'],
    fontSize: '0.7rem',
    fontWeight: 700,
    padding: '0.1rem 0.8rem',
    backgroundColor: variables.colorPurple,
    borderRadius: '10px',
    'a' : {
      color: 'white',
      '&:hover' : {
        textDecoration: 'none'
      }
    }
  })

}