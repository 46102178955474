//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    }),
    pageTile__tile: css(mq({
        flex: '0 0 100%',
        maxWidth: '100%',
        padding: '10px',
        '&:nth-of-type(even)': {
            '.pageTile__bsyc__bg': mq({
                //order: ['0', null, '2'],
            }),
            '.pageTile__bsyc__content__details': mq({
                //order: '1'
            })
        }
    })),

    tile__content: css({
        //     display: 'flex',
        //     flexDirection: 'row',
        //     justifyContent: 'space-between',
        //     width: '100%',
        //     height: '100%',
        //     cursor: 'pointer',
        //     position: 'relative',
        //     flexWrap: 'wrap',
        '&:hover': {
            textDecoration: 'none',
            // '& .pageTile__bsyc__wrapper': {
            //     boxShadow: '0px 5px 5px rgba(0,0,0,0.5)',
            // },
            '& .pageTile__bsyc__content__details': {
                color: 'white',
                backgroundColor: variables.colorPurple,
                backgroundImage: 'url(/assets/grey-bottle-img.png)',
                boxShadow: '0px 5px 5px rgba(0,0,0,0.5)',
                '& .pageTile__bsyc__title': {
                    color: 'white'
                },
                '& .pageTile__bsyc__desc': {
                    color: 'white',
                    fontWeight: '400'
                },
                '& .pageTile__bsyc__learn__more': {
                    backgroundColor: 'transparent',
                    borderColor: 'white',
                    color: 'white'
                }
                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
            },
            '& .pageTile__bsyc__bg': {
                boxShadow: '0px 5px 5px rgba(0,0,0,0.5)'
            },
            '& .pageTile__bsyc__bg2': {
                //transform: scale(1.05)
                transform: 'scale(1.05)',

            }
        }
    }),

    tile__flip__wrapper: css({
        zIndex: '-1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap',
        //transition: 'all .2s ease-in-out',
    }),

    //     tile__bg : css(mq({
    //          height: '0',
    //          //paddingTop: '30%',
    //          paddingTop: ['50%','35%','30%'],
    //          backgroundPosition: '50%',
    //          backgroundRepeat: 'no-repeat',
    //          backgroundSize: 'cover',
    //          flex: '1 1 300px'
    //     })),

    tile__bg: css(mq({
        height: '0',
        paddingTop: ['50%', '35%', '30%'],
        //height: ['50%','35%','30%'],
        //flex: '1 1 300px',
        flex: ['1 0 100%', null, '1 0 40%'],
        maxWidth: ['100%', null, '40%',],
        overflow: 'hidden',
        position: 'relative',
        //boxShadow: '0px 5px 5px rgba(0,0,0,0.5)'
    })),

    tile__bg2: css(mq({
        height: '100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //overflow:'hidden',
        //transition: 'all .2s',
        transition: 'all .2s ease-in-out',
        position: 'absolute',
        top: '0px',
        width: '100%'
    })),

    tile__details: css(mq({
        overflow: 'hidden',
        flexGrow: '1',
        textAlign: 'left',
        width: '100%',
        bottom: '0',
        backgroundColor: variables.colorGrey,
        //backgroundImage: 'url(/assets/red-bottle-img.png)',
        //backgroundRepeat: 'no-repeat',
        //backgroundPosition: 'center',
        //backgroundSize: 'contain',
        //boxShadow: '0px 5px 5px rgba(0,0,0,0.5)',
        //     '&.default' : css({
        //           backgroundColor: 'rgba(0, 113, 188,.5)'
        //     }),
        //     '&.red' : css({
        //           backgroundColor: 'rgba(211, 61, 2,.5)'
        //      }),
        //      '&.green' : css({
        //           backgroundColor: 'rgba(0, 146, 69,.5)'
        //      }),
        //backgroundColor : '#77cf22',
        //flex: '1 1 300px',
        flex: ['1 0 100%', null, '1 0 60%'],
        maxWidth: ['100%', null, '60%',],
        transition: 'all .2s ease-in-out',
        //color: '#ebebeb',
        position: 'relative',
        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static,', null, 'absolute'],
            top: '50%',
            left: '50%',
            transform: ['none', null, 'translate(-50%, -50%)'],
            width: ['100%', null, '90%'],
            //padding: ['1rem', null, '0px'],
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: ['1rem',null,'0'],
            paddingBottom: ['1rem',null,'0'],
            '& .pageTile__bsyc__title': {
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: '2rem',
                //textTransform: 'uppercase',
                //fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                //paddingTop: '.5rem',
                //paddingBottom: '.5rem',
                color: variables.colorGreen,
                fontWeight: 700,
                //borderBottom: '1px solid white',
                display: 'inline-block'
            },
            '& .pageTile__bsyc__desc': mq({
                //lineHeight: '1.7',
                fontSize: '.9rem',
                //color: '#3d3d3d',
                //minHeight: '70px',
                color: variables.fontColor,
                marginTop: '1rem',
                fontWeight: '400',
                lineHeight : ['1.2',null,'1.5']
            }),
            '& .pageTile__bsyc__learn__more': {
                display: 'inline-block',
                marginTop: '1rem',
                padding: '0.1rem 1.5rem',
                backgroundColor: variables.colorPurple,
                border: '1px solid ' + variables.colorPurple,
                color: 'white',
                fontWeight: '700',
                borderRadius: '10px',
                fontSize: '0.83rem'
                //transition: 'all .2s ease-in-out'
            }
        })
    }))
}